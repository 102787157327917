import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader='Walking on water and developing software from a specification are easy if both are frozen.'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img image={image} />
            <Button sx={styles.button}>Contact Me</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='My Story'>
                <Text variant='p'>
                  When I was ten years old I was lucky enough to get a Sinclair ZX81, and that is
                  when my love affair with the computer and programming began.
                </Text>
                <Text variant='p'>
                  I put this blog together for many reasons, to write notes on my learnings, a place to
                  have a good moan and a place for me to put some tutorials on for students that I
                  mentor at Code Institute.
                </Text>
                <Text variant='p'>
                  In my career I worked for myself, small companies, big multinationals like PwC and
                  governments. The one thing that has stayed strong throughout my career, which on occasion
                  has brought me many challenges, is my love for coding solutions.
                </Text>
                <Text variant='p'>
                  The software I have worked with over the years include:
                </Text>
                <Text variant='p'>
                  <ul>
                    <li>Python (Django, Flask, FastAPI, Django REST Framework))</li>
                    <li>Javascript (React, Vue, Express, Node, Stencil)</li>
                    <li>Other Front End (HTML, CSS, SASS, SCSS)</li>
                    <li>Clouds (GCP, AWS, Azure)</li>
                    <li>Databases (Postgres, MySQL, MS SQL, Mongo)</li>
                  </ul>
                </Text>
                <Text variant='p'>
                  When I started my career agile wasn't a thing so I've worked in teams
                  using waterfall and now work within a SCRUM methodology with all the
                  usual Agile ceremonies.
                </Text>
                <Text variant='p'>
                  Whilst at PwC I was a senior manager looking after four teams, a total of
                  about forty people; I have learnt many soft skills and dealt with pressure
                  though really at heart I'm a coder and despite being able to manage I'd prefer
                  not to.
                </Text>
                <Text variant='p'>
                  Hobby wise I'm into cars, travelling, chess, music, films and cooking - there's
                  just not enough hours in the day... Have a look around and if you like drop me a
                  message, it'll be great to hear from you!
                </Text>
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='Code Mentoring'>
                <Card variant='paper'>
                  I have been mentoring at the Code Institute since 2019 and thoroughly enjoy it.
                  I help students through their course in full stack software dev. The technologies
                  we cover are HTML, CSS, Javascript, React, Python, Flask with Mongo and Django with
                  Postgres (quite often a eCommerce site using Strip payments)
                </Card>
              </Section>
              <Divider />
              <Section title='Why do I blog?'>
                <Card variant='paper'>
                  Mostly for selfish reasons. I wanted a way to keep track of my software development over time –
                  whatever I am thinking about or working on. I research and dabble with things I find interesting,
                  then my plan is to document this with a post, which I can easily find and refer to later.
                  </Card>
              </Section>
              <Divider />
              <Section title='Where am I located?'>
                <Card variant='paper'>
                  I live in Northern Ireland, where they filmed some of the Game of Thrones, near the Giant Causeway.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
